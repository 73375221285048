import React from 'react';
import '../style.css';

class Education extends React.Component {
  render() {
    return (
      <div className="education-section">
        <h2 className="education-title">Education</h2>
        <div className="timeline">
          <div className="timeline-item">
            <div className="timeline-content left">
              <h3>08/2021 - now</h3>
              <h4>Informatiker EFZ</h4>
              <p>Applikationsentwicklung</p>
              <p>🏫 gibb, Bern</p><br></br>
              <a href="https://gibb.ch/" className="learn-more-btn" target="_blank" rel="noopener noreferrer">Learn more</a>
            </div>
          </div>
          <div className="timeline-item">
            <div className="timeline-content right">
              <h3>08/2021 - now</h3>
              <h4>Informatikmittelschule</h4>
              <p>Berufsmaturität "Wirtschaft"</p>
              <p>🏫 bwd, Bern</p><br></br>
              <a href="https://bwdbern.ch/" className="learn-more-btn" target="_blank" rel="noopener noreferrer">Learn more</a>
            </div>
          </div>
          <div className="timeline-item">
            <div className="timeline-content left">
              <h3>08/2018 – 07/2021</h3>
              <h4>Sekundarstufe</h4>
              <p>🏫 Sekundarschule Erlach, Erlach</p><br></br>
              <a href="http://www.oberstufe-erlach.ch/" className="learn-more-btn" target="_blank" rel="noopener noreferrer">Learn more</a>
            </div>
          </div>
          <div className="timeline-item">
            <div className="timeline-content right">
              <h3>08/2012 – 07/2018</h3>
              <h4>Primarschule</h4>
              <p>🏫 Primarschule Erlach, Erlach</p><br></br>
              <a href="https://www.erlach.ch/de/verwaltung/abteilungen/19_primarschule-erlach" className="learn-more-btn" target="_blank" rel="noopener noreferrer">Learn more</a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Education;
